/* eslint-disable */
import { store } from "@/store";
import moment from "moment";

const language = window.localStorage.getItem('language') || 'en' 
class Validations {
    public static country_code = '+961'
    public static email_taken = false;
    public static phone_taken = false;

    public static customer() {
        return store.state.personals.customer
    }
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تسجيل بريدك الإلكتروني' : 'Please enter your email'))
        } else if(!re.test(String(value).toLowerCase())) {
            callback(new Error( language === 'ar' ? 'يجب أن يكون عنوان البريد الإلكتروني صالحًا' : 'Email address must be valid'))
        } else if(Validations.email_taken) {
            callback(new Error( language === 'ar' ? 'Email already taken' : 'Email already taken'))
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        let isvalidtext = /^[a-zA-Z_\s\.'-]+$/;
        let singleCount = value ? value.match(/[']/g) : false;
        let minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if(singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if(minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسمك بالكامل' : 'Please enter your full name'))
        } else if(((!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker))) {
            callback(new Error(`Only character a-z, - and ' allowed`))
            // language.value === 'ar' ? `فقط الأحرف a-z ، - و ' مسموحة` : 
        } else {
            callback();
        }
    }

    public static first_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter your first name'));
        } else {
            callback();
        }
    }

    public static last_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter your last name'));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter your company name'));
        } else {
            callback();
        }
    }

    public static number_members(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter number of members'));
        } else if(value < 1) {
            callback(new Error('Member count must not be less than or equal to 0'))
        } else {
            callback();
        }
    }

    public static member_name(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error('Please enter member full name'));
        } else {
            callback();
        }
    }

    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم الهاتف' : 'Please enter phone number'))
        } 
        
        if (Validations.country_code === '+961' && (value < 999999 || value > 99999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 7 أرقام على الأقل و8 أرقام كحد أقصى.' : 'Please enter at least 7 digits and max 8 digits.'))
        } else if (Validations.country_code !== '+961' && (value < 9999999 || value > 9999999999)) {
            callback(new Error( language === 'ar' ? 'يرجى إدخال 8 أرقام على الأقل و 10 أرقام كحد أقصى.' : 'Please enter at least 8 digits and max 10 digits.'))
        } else {
            callback();
        }
    }

    public static insured_type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(`Please select insured type`));
        } else {
            callback();
        }
    }

    public static emirate(rule: any, value: any, callback: any) {
        if(!value && value != 0){
            callback(new Error(`Please select city`));
        }else {
            callback();
        }  
    }

    public static dob(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
        } else if(moment().diff(value, 'days') < 0) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد تاريخ ميلاد صحيح' : 'Please select correct date of birth'));
        } else {
            callback();
        }
    }

    public static insurance_date(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد التاريخ الصحيح' : `Please select correct date`));
        } else {
            callback();
        }
    }

    public static start_date(rule: any, value: any, callback: any) {
        console.log('momenm');
        console.log(value);
        console.log(moment().diff(value, 'days'));
        if(value === 'Invalid date' || !value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد التاريخ الصحيح' : `Please select correct date`));
        } else if(moment().diff(value, 'days') > 0) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد التاريخ الصحيح' : `Please select correct date`));
        } else {
            callback();
        }
    }

    public static end_date(rule: any, value: any, callback: any) {
        if(value === 'Invalid date' || !value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد التاريخ الصحيح' : `Please select correct date`));
        } else {
            callback();
        }
    }

    public static day(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد التاريخ' : `Please select date`));
        } else {
            callback();
        }
    }

    public static month(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء اختيار الشهر' : `Please select month`));
        } else {
            callback();
        }
    }

    public static year(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد السنة' : `Please select year`));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد الجنسية' : `Please select nationality`));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد الجنس': 'Please select gender'))
        } 
        else {
            callback();
        }
    }

    public static country_of_residence(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء اختيار بلد الإقامة' :  `Please select country of residence`));
        } else {
            callback();
        }
    }

    public static nationality_id(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء اختيار بلد الإقامة' :  `Please select nationality`));
        } else {
            callback();
        }
    }

    public static type(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === "ar" ? "الرجاء تحديد النوع" : "Please select type"))
        } else {
            callback();
        }
    }

    public static passport(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء إدخال رقم جواز السفر' : `Please enter passport number`));
        } else {
            callback();
        }
    }
    
    public static destination(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد الوجهة' : `Please select destination`));
        } else if(value.length == 0) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد الوجهة' : `Please select destination`));
        } else {
            callback();
        }
    }
    
    public static source(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد المصدر' : `Please select source`));
        } else {
            callback();
        }
    }

    public static relationship(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error(language === 'ar' ? 'الرجاء تحديد العلاقة' : `Please select relationship`));
        } else {
            callback();
        }
    }

    public static passportNumber(rule: any, value: any, callback: any) {
        let isvalidtext = /^[a-zA-Z0-9]+$/;
        let singleCount = value ? value.match(/[']/g) : false;
        let minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if(singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if(minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم جواز السفر' : 'Please enter passport number'))
        } else if(((!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker))) {
            // callback(new Error(`Only characters a-z and 0-9 are allowed`))
            callback(new Error(language === 'ar' ? 'يُسمح فقط باستخدام الأحرف a-z و0-9' : 'Only characters a-z and 0-9 are allowed')) 
        } else {
            callback();
        }
    }

}

export default Validations;
