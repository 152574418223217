<template>
    <el-dialog
    class="box-card existing_leads existing-leads-modal"
    v-model="open"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    center
    :width="screen.width > 768 ? '40%' : '90%'"
  >
    <template #header>
      <div class="card-header">
        <span class="text-center" style="overflow-wrap: break-word">{{ $t("system_error_title") }}</span>
      </div>
    </template>
    <p class="system_err_msg"> {{ $t("system_error_text") }} </p>
    <el-divider></el-divider>
    <el-button type="primary" size="large" @click="refresh">{{
      $t("system_refresh")
    }}</el-button>
  </el-dialog>
</template>

<script setup>
import { store } from "@/store";
import { computed } from "vue";
import { screen } from "@/store/stateless";

const open = computed(() => {
    return store.state.quotes.hasError
})

function refresh() {
    window.location.reload()
}
</script>

<style>
.system_err_msg{
display: block;
word-break: break-word;
}
</style>
