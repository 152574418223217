const en = {
  test: "الإنجليزية",
  personal: "الشخصية",
  details: "تفاصيل",
  declaration: "إقرار",
  compare: "قارن",
  quotes: "يقتبس",
  purchase: "الشراء",
  insurance: "التأمين",
  personal_details: "التفاصيل الشخصية",
  will_take: "تستغرق العملية أقل من 1 دقيقة",
  full_name: "الاسم الكامل",
  full_name_tooltip: "this is a test tooltip arabic",
  phone_number: "رقم الهاتف",
  phone_number_placeholder: "رقم الهاتف",
  insured_type: "نوع المؤمن عليه",
  city: "التأشيرة صادرة في (الإمارات)",
  city_tooltip: "المدينة",
  date_of_birth: "تاريخ الميلاد",
  date_of_birth_tooltip: "تاريخ الميلاد",
  day: "اليوم",
  month: "الشهر",
  year: "السنة",
  nationality: "الجنسية",
  yes: "نعم",
  no: "لا",
  salary: "هل راتبك يزيد عن {salary} درهم في الشهر؟",
  expected_insurance: "التاريخ المتوقع لبدء التأمين",
  expected_insurance_tooltip: "التاريخ المتوقع لبدء التأمين",
  cover_for: "تغطية لأجل",
  plan_benefits: "منافع الخطة",
  travel_details: "تفاصيل السفر",
  medical_cover: 'التغطية الطبية',
  luggage_loss: 'فقدان الأمتعة',
  disclaimer_details: 'أؤكد/نؤكد بأن كل مسافر مشمول بهذه الوثيقة يقيم في لبنان ولم يسافر خارج لبنان في أثناء شراء هذه الوثيقة. جميع المعلومات المقدمة في هذه الاستمارة دقيقة، ولم تُحذف أية تفاصيل أساسية قد تؤثر على هذه الوثيقة. أقر/نقر أيضًا بأن أي تقاعس في الكشف عن الحقائق أو تحريفها أو إخفائها قد يجعل هذه الوثيقة غير صالحة وغير قابلة للاسترداد.',
  travel: "السفر",
  traveller_seq: "المسافر {count}",
  traveller_dob_label: "المسافر {count}",
  no_of_traveller_placeholder: 'عدد المسافرين',
  no_of_traveller: 'عدد المسافرين',
  all_plan_benefits: "جميع منافع الخطة",
  traveller_dob_placeholder: "تاريخ الميلاد",
  start_date: "تاريخ البدء",
  start_date_tooltip: "تاريخ البدء",
  end_date: "تاريخ الانتهاء",
  end_date_tooltip: "تاريخ الانتهاء",
  destination: "إلى أين ستسافر",
  destination_tooltip: "إلى أين ستسافر؟",
  source: "من أين ستسافر",
  source_tooltip: "من أين ستسافر؟",
  inbound_outbound: "هل أنت مسافر ",
  qoute_reference: "مرجع عرض السعر",
  uae: " لبنان؟",
  type: 'نوع',
  country_of_residence: 'بلد الإقامة',
  type_tooltip: 'نوع',
  first_name: "الاسم الأول",
  first_name_tooltip: "الاسم الأول",
  middle_name: "الاسم الأوسط",
  middle_name_tooltip: "الاسم الأوسط",
  last_name: "اسم العائلة",
  last_name_tooltip: "اسم العائلة",
  traveller: "المسافر",
  passport: "رقم جواز السفر",
  email_address: "البريد الإلكتروني",
  email_address_placeholder: "عنوان البريد الإلكتروني",
  email_address_tooltip: "عنوان البريد الإلكتروني",
  gender: 'الجنس',
  member_date_of_birth: "تاريخ ميلاد العضو",
  relationship: 'الصلة',
  member_full_name: 'الاسم الكامل',
  member_details: 'أدخل تفاصيل المسافر',
  member_dob: 'تاريخ الميلاد',
  into: 'إلى',
  outof: 'من',
  company_name: 'اسم الشركة',
  employee_full_name: 'الاسم الكامل',
  company_contact_number: 'رقم الاتصال',
  company_email_address: 'عنوان البريد الإلكتروني للشركة',
  company_no_members: 'عدد الأعضاء',
  add_member: 'إضافة مسافر',
  add_another_member: 'أضف مسافرًا آخر',
  is_part_of_family: 'هل هو فرد من العائلة؟',
  save_edited_member: 'حفظ المسافر',
  member: 'المسافر',
  edit_details: 'تعديل التفاصيل',
  delete: 'الحذف',
  name: 'الاسم',
  salary_more_than: 'الراتب > 4,000 درهم',
  thank_you: 'شكرًا لك',
  quotes_count: 'لقد وجدنا لك {count} عروض أسعار لتأمين السفر',
  price_vat: 'الأسعار التي تراها هنا لا تشمل {vat}% ضريبة القيمة المضافة',
  show_all_btn: 'إظهار الكل ({count}) الموجودة في {policy}',
  show_less_btn: 'إظهار أقل الموجودة في {policy}',
  we_cannot_process: 'لا يمكننا معالجة طلبك أون لاين. سيتصل بك فريقنا في أقرب فرصة!',
  no_quotes_point_one: 'تندرج متطلباتك ضمن فئة خاصة ترغب شركات التأمين في معرفة المزيد عنها.',
  secure_checkout: "الخروج الآمن",
  show_full_summary: 'عرض الملخص الكامل',
  full_summary: 'الملخص الكامل',
  hide_full_summary: 'إخفاء الملخص الكامل',
  summary: "الملخص",
  premium: "القسط",
  vat: "ضريبة القيمة المضافة",
  vat_t: "ضريبة القيمة المضافة {vat} %",
  total_amount: "المبلغ الإجمالي",
  agree: 'أوافق على',
  download: 'تنزيل',
  payable_amount: 'المجموع المستحق للدفع',
  no_quotes_found : 'لم نجد عروض أسعار!!',
  compare_offer: 'قارن العروض',
  compare_plan: 'قارن الخطط',
  back : 'العودة إلى الصفحة الرئيسية',
  some_extra_information: 'بعض المعلومات الإضافية مطلوبة',
  declaration_que_ans: 'تبعًا لإجابات الإقرار الخاصة بك، سنطلب معلومات إضافية من أجل استحداث عروض الأسعار. سنتصل بك قريبًا لإنهاء العملية.',
  no_declaration_que_ans: 'سنطلب معلومات إضافية من أجل استحداث عروض الأسعار. سنتصل بك قريبًا لإنهاء العملية.',
  alternatively_call_us: 'أو اتصل بنا على <a href="tel:01481565">01481565 </a>للحصول على المساعدة الفورية',
  is_married: 'متزوج؟',
  is_self: 'هل تحتاج إلى التأمين لنفسك؟',
  all: 'الكل',
  basic: 'الأساسي',
  enhance: 'المتقدم',
  buy_now: 'اشتر الآن',
  select_existing_lead : 'اختر من البيانات المدخلة الموجودة',
  enter_new_details: 'أدخل تفاصيل جديدة',
  get_insured: "احصل على تأمين",
  car_insurance: "تأمين السيارة",
  health_insurance: "التأمين الصحي",
  expat_insurance: 'تأمين المغتربين',
  expat_health_insurance: 'التأمين الصحي للمغتربين ',
  pet_insurance: 'تأمين الحيوانات الأليفة',
  travel_insurance: 'تأمين السفر',
  pet: 'تأمين الحيوانات الأليفة',
  home_insurance: "تأمين المنزل",
  bike_insurance: "تأمين الدراجة النارية",
  other_insurance: "تأمينات أخرى",
  renew_a_policy: "تجديد التأمين",
  company: "الشركة",
  about: "نبذة عنّا",
  insurance_partners: "شركاء التأمين",
  hr_solutions: "حلول الموارد البشرية",
  blog: "المدونة",
  quick_links: "روابط سريعة",
  privacy_page: "صفحة الخصوصية",
  tac: "الأحكام والشروط",
  ami: "تأمين المنارة",
  edarat: "E-DARAT",
  support: "الدعم",
  help_center: "مركز المساعدة",
  contact: "اتصل بنا",
  cancellation: "إلغاء التأمين",
  calculator: "الحاسبات",
  car_loan: "قرض السيارة",
  mortgage: "القرض العقاري",
  insurance_calc: "حاسبة التأمين",
  mail: 'البريد الإلكتروني',
  head_office: 'المكتب الرئيسي',
  need_assistance: 'طلب المساعدة',
  call_us: 'أو اتصل بنا على',
  support_center: 'طلب المساعدة',
  corporate: 'الشركات',
  solution: 'حلول',
  address: 'سنتر حدائق الباشا، بلوك ب، الطابق الأول، جسر الباشا. لبنان',
  disclaimer: 'تنصل',
  disclaimer_text: 'شركة بريميوم تشويس ماركتينج مكتب 1406 داماك سمارت هايتس، برشا هايتس، دبي، الإمارات هي المالكة للاسم التجاري "BuyAnyInsurance.com". التأمين مُقدّم من قبل شركة المنارة لخدمات التأمين برخصة رقم 47 من هيئة التأمين',
  company_of: 'إحدى شركات ',
  proceed: "أكمل",
  wait: "الرجاء الانتظار",
  corporate_solution: 'حلول الشركات',
  thank_you_msg : 'إذا كنت ترغب في استعراض عروض الأسعار، يرجى النقر على "إلغاء" ثم أدخل تفاصيل العضو في صفحة التفاصيل الشخصية. أما إذا كنت ترغب في إعادة الاتصال بك، فيرجى النقر على زر "طلب إعادة الاتصال".',
  cancel : 'إلغاء',
  request_call_back:'طلب إعادة الاتصال',
  no_quotes_manual_quote_title: 'للحصول على عرض سعر يدوي اتصل بنا على <span dir="ltr">01 481 565</span>',
  callback_thank_you_msg: 'شكرًا لك على اختيار طلب إعادة الاتصال. سيتصل بك فريقنا في أقرب فرصة!',
  go_back: 'العودة إلى الصفحة الرئيسية',
  AED: "$",
  annual_limit: 'الحد السنوي',
  scope_coverage: 'نطاق التغطية',
  total_premium:'إجمالي الأقساط',
  member_premium:'قسط العضو',
  add_compare:'أضف للمقارنة',
  show_more:'إظهار المزيد',
  show_less:'إظهار أقل',
  disclaimers: 'إخلاء المسؤولية',
  copyrights: 'الحقوق محفوظة',
  minimum_traveller_is_required: 'مطلوب مسافر واحد على الأقل',
  mandatory_traveller_fields_are_required: 'حقول المسافر الإلزامية مطلوبة',
  review_details: 'مراجعة التفاصيل',
  review: 'مراجعة',
  searching_for_insurance: 'البحث عن خطة تأمين السفر',
  review_page_loading_text: 'جلب سجل معلومات المسافر وتفاصيل الخطة',
  passport_tooltip:'أدخل الاسم كما هو مكتوب في جواز السفر',
  extra_addon: "ملحقات إضافية",
  title: "اللقب",
  policy_for: 'وثيقة لأجل',
  from: 'من',
  go_back_home: 'العودة إلى الصفحة الرئيسية',
  destination_label: 'الأماكن',
  google_map: 'خرائط جوجل',
  copyrights_footer: 'التأمين موضوع طلب. © حقوق الطبع والنشر - DATE_FOOTER BuyAnyInsurance.com.',
  addons: 'الإضافات',
  system_error_title: ' لقد حدث خطأ ما',
  system_error_text: 'عذرًا، لقد حدث خطأ في أثناء جلب البيانات. الرجاء النقر فوق زر التحديث لإعادة التحميل.',
  system_refresh: 'التحديث',
  faqs: "الأسئلة المتداولة",
  coupons: 'هل لديك قسيمة خصم؟',
  coupon_applied: 'تم استخدام قسيمة الخصم',
  coupon_ph: 'كوبون',
  apply: "استخدم",
  remove: "احذف",
  discount: "الخصم",
};
export default en;
