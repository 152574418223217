const ar = {
  test: "English",
  personal: "Personal",
  details: "Details",
  declaration: "Declaration",
  compare: "Compare",
  quotes: "Quotes",
  purchase: "Purchase",
  insurance: "Insurance",
  personal_details: "Personal Details",
  will_take: "This will only take 1 minute!",
  full_name: "Full Name",
  full_name_tooltip: "this is a test tooltip english",
  phone_number: "Phone Number",
  phone_number_placeholder: "Phone Number",
  insured_type: "Insured Type",
  city: "Visa Issued In (Emirates)",
  city_tooltip: "City",
  date_of_birth: "Date of Birth",
  date_of_birth_tooltip: "Date of Birth",
  day: "Day",
  select_existing_lead : 'Select from existing entered data',
  enter_new_details: 'Enter New Details',
  month: "Month",
  year: "Year",
  nationality: "Nationality",
  yes: "Yes",
  no: "No",
  salary: "Is your salary more than $ {salary} per month?",
  expected_insurance: "Expected Insurance Start Date",
  expected_insurance_tooltip: "Expected Insurance Start Date",
  cover_for: "Cover for",
  plan_benefits: "Plan Benefits",
  qoute_reference: "Quote reference",
  travel_details: "Travel Details",
  travel: "Travel",
  traveller_seq: "Traveller {count}",
  traveller_dob_label: "Traveller {count}",
  traveller_dob_placeholder: "Date of birth",
  no_of_traveller_placeholder: 'No. of Travellers',
  no_of_traveller: 'No of travellers',
  all_plan_benefits: "All Plan Benefits",
  start_date: "Trip Start Date",
  start_date_tooltip: "The date your trip begins",
  end_date: "Trip End Date",
  end_date_tooltip: "The date your trip ends",
  destination: "Where are you travelling to",
  destination_tooltip: "Where are you travelling to?",
  source: "Where are you travelling from",
  source_tooltip: "Where are you travelling from?",
  inbound_outbound: "Are you travelling ",
  uae: " Lebanon?",
  type: 'Type',
  country_of_residence: 'Country of Residence',
  type_tooltip: 'Type',
  first_name: "First Name",
  first_name_tooltip: "First Name",
  middle_name: "Middle Name",
  middle_name_tooltip: "Middle Name",
  last_name: "Last Name",
  last_name_tooltip: "Last Name",
  traveller: "Traveller",
  passport: "Passport Number",
  email_address: "Email",
  email_address_placeholder: "Email address",
  email_address_tooltip: "Email address",
  gender: 'Gender',
  member_date_of_birth: "Member's date of birth",
  relationship: 'Relationship',
  member_full_name: 'Full Name',
  member_details: 'Enter traveller details',
  member_dob: 'Date of Birth',
  into: 'into',
  outof: 'out of',
  company_name: 'Company Name',
  employee_full_name: 'Full Name',
  company_contact_number: 'Contact Number',
  company_email_address: 'Company Email Address',
  company_no_members: 'No. Of Members',
  add_member: 'Add traveller',
  add_another_member: 'Add another traveller',
  is_part_of_family: 'Is part of family?',
  save_edited_member: 'Save traveller',
  member: 'Traveller',
  edit_details: 'Edit details',
  delete: 'Delete',
  name: 'Name',
  salary_more_than: 'Salary > $ 4,000',
  thank_you: 'Thank You',
  disclaimer_details: 'I/we verify that each traveler covered by this policy resides in Lebanon and has not undertaken any travel outside Lebanon at the time of purchasing this policy. All information supplied with this application is accurate, and no essential details have been omitted that might impact this policy. I/We also acknowledge that any failure to disclose, misrepresentation, or concealment of facts may render this policy invalid and nonrefundable.',
  some_extra_information: 'Some extra information is required',
  declaration_que_ans: 'Due to your declaration answers, we will require additional information in order to generate quotes. <br/>We will call you shortly to finish the process.',
  no_declaration_que_ans: 'We will require additional information in order to generate quotes. <br/>We will call you shortly to finish the process.',
  alternatively_call_us: 'Alternatively, call us at <a href="tel:01481565">01 481 565</a> for immediate assistance.',
  is_married: 'Is Married?',
  is_self: 'Do you need insurance for yourself?',
  all: 'All',
  basic: 'Basic',
  enhanced: 'Enhanced',
  get_insured: "Get Insured",
  car_insurance: "Car Insurance",
  health_insurance: "Health Insurance",
  expat_insurance: 'Expat Insurance',
  expat_health_insurance:'Expat Health Insurance',
  pet_insurance: 'Pet Insurance',
  travel_insurance: 'Travel Insurance',
  pet: 'Pet',
  home_insurance: "Home Insurance",
  bike_insurance: "Bike Insurance",
  other_insurance: "Other Insurance",
  renew_a_policy: "Renew a Policy",
  company: "Company",
  about: "About",
  insurance_partners: "Insurance Partners",
  hr_solutions: "HR Solutions",
  blog: "Blog",
  quick_links: "Quick links",
  privacy_page: "Privacy Page",
  tac: "Terms & Conditions",
  ami: "Al Manarah insurance",
  edarat: "E-DARAT",
  support: "Support",
  help_center: "Help Center",
  contact: "Contact",
  cancellation: "Cancellation",
  calculator: "Calculators",
  car_loan: "Car Loan",
  mortgage: "Mortgage",
  insurance_calc: "Insurance Calc",
  mail: "Email",
  head_office: "Head Office",
  need_assistance: "NEED ASSISTANCE",
  call_us: "or Call us on",
  support_center: "Visit Support Center",
  corporate: "Corporate",
  solution: "Solutions",
  address: 'Al Bacha Gardens Center, Block B, 1st floor, Jisr el Bacha, Lebanon',
  disclaimer: 'Disclaimer',
  disclaimer_text:'Premium Choice Marketing LLC office 1406, Damac Smart Heights, Barsha Heights, Dubai, UAE is the owner of the brand name “ BuyAnyInsurance.com". Insurance is provided by Chedid Insurance Brokers SAL.',
  company_of: 'A company of',
  proceed: "Proceed",
  wait: "Please wait...",
  corporate_solution: 'Corporate Solutions',
  thank_you_msg : 'If you need to view quotes kindly click Cancel & enter the member details on the Personal details screen. If you want a callback kindly click the Request a Call button.',
  cancel : 'Cancel',
  request_call_back:'Request a Call',
  no_quotes_manual_quote_title: 'For a manual quotation, please contact us on 01 481 565 or ',
  callback_thank_you_msg: 'Thank you for submitting a callback request . Our team will contact you soon !',
  go_back: 'Go back to Homepage',
  AED: "$",
  annual_limit: 'Annual Limit',
  scope_coverage: 'Scope of Coverage',
  maternity_coverage: 'Maternity Coverage',
  total_premium:'Total Premium',
  buy_now:'Buy now',
  member_premium:'Member Premium',
  add_compare:'Add to compare',
  show_more:'Show more',
  show_less:'Show less',
  price_vat: 'The prices you see here are exclusive of {vat}% VAT',
  quotes_count: 'We have found {count} travel insurance quotes for your trip to ',
  quotes_count_destination: ' {destination}',
  quotes_count_dates: ' from {startdt} to {enddt}',
  show_all_btn: 'Show All ({count}) available in {policy}',
  show_less_btn: 'Show Less available in {policy}',
  we_cannot_process: 'We cannot process your application online. Our team will contact you shortly.',
  no_quotes_point_one: 'Your rquirements falls under a specific that insurance companies would like to know more about',
  secure_checkout: "Secure Checkout",
  show_full_summary: 'Show Full Summary',
  full_summary: 'Full Summary',
  hide_full_summary: 'Hide Full Summary',
  summary: "Summary",
  premium: "Premium",
  vat: "VAT",
  vat_t: "VAT {vat} %",
  total_amount: "Total Amount",
  agree: 'Agree to the',
  download: 'Download',
  payable_amount: 'Payable Total',
  no_quotes_found : 'No Quotes Found !!',
  compare_offer: 'Compare Offer',
  compare_plan: 'Compare Plans',
  back : 'Back',
  disclaimers: 'DISCLAIMERS',
  copyrights: 'Copyrights',
  minimum_traveller_is_required: 'Minimum 1 traveller is required',
  mandatory_traveller_fields_are_required: 'Mandatory traveller fields are required',
  review_details: 'Review Details',
  review: 'Review',
  searching_for_insurance: 'Searching for travel insurance plan',
  review_page_loading_text: 'Fetching record for the traveler info and Plan details',
  passport_tooltip:'Enter Name as on passport',
  extra_addon: "Extra Addons",
  title: "Title",
  medical_cover: 'Medical Cover',
  policy_for: 'Policy For',
  from: 'from',
  go_back_home: 'Go back to home page',
  luggage_loss: 'Luggage Loss',
  destination_label: 'Destinations',
  google_map: 'Google Map',
  copyrights_footer: 'Insurance is the subject matter of solicitation. © Copyright-DATE_FOOTER BuyAnyInsurance.com.',
  addons: 'Addons',
  system_error_title: 'An Error Occured',
  system_error_text: 'Sorry, there was an error while fetching the data. Please click the refresh button to reload',
  system_refresh: 'Refresh',
  faqs: 'FAQs',
  coupons: 'Do you have a coupon?',
  coupon_applied: 'Coupon Applied',
  coupon_ph: 'Coupon',
  apply: "Apply",
  remove: "Remove",
  discount: "Discount",
};

export default ar;
