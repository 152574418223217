import { ActionTree, ActionContext } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CheckoutMutationTypes } from '@/core/enum/Checkouts/Mutations';
import { CheckoutsActionTypes } from '@/core/enum/Checkouts/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [CheckoutsActionTypes.POST_CHECKOUT](
    { commit }: AugmentedActionContext,
    payload: any
  ): any;
  [CheckoutsActionTypes.GET_PAYFORT_FORM](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CheckoutsActionTypes.POST_CHECKOUT]({ commit }, payload) {
    try {
      const source = window.localStorage.getItem('sources')
      const data = await axios.post(Links.POST_CHECKOUT, payload);
      const path = source?.slice(1);
      const urlRedirect = process.env.VUE_APP_PAYMENT_GATEWAY_URL + '/' + payload.language + '/miscellaneous/payments/travel/' + payload.merchant_reference + (path != undefined ? '?' + path : '') 
      window.location.href = urlRedirect
      return data;
    }
    catch(err) {
      return err;
    }
  },

  async [CheckoutsActionTypes.GET_PAYFORT_FORM]({commit}, payload) {
    const data = await axios.post(Links.GET_PAYFORT_DATA, payload);
    try {
      commit(CheckoutMutationTypes.SET_PAYMENT, data.data);
    } catch (error) {
      return error;
    }
  }

};
